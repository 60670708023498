import Dropdown from 'react-bootstrap/Dropdown';
import NavDropdown from 'react-bootstrap/NavDropdown';

import './Header.css';

const Header = function () {
  const phoneNum = `(609) 516-1699`;
  return (
    <header className="head parent">
      <h1>JAKE HARRIS</h1>
      <NavDropdown
        id="dropdown-basic-button"
        className="dropdown"
        title="CONTACT INFO"
      >
        <Dropdown.Item href="mailto:jakeharris1078@gmail.com">
          jakeharris1078@gmail.com
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            navigator.clipboard.writeText(phoneNum);
          }}
        >
          {phoneNum}
        </Dropdown.Item>
      </NavDropdown>
    </header>
  );
};

export default Header;
