import './RS.css';
import LikeBar from './LikeBar';
import { useState } from 'react';

function StatusBox(props) {
  let status = props.statusText;

  const [style, setStyle] = useState({});

  const hideClickHandler = function (e) {
    e.preventDefault();
    setStyle({ display: 'none' });
  };

  return (
    <div className="statusbox" style={style}>
      <div className="statustext"> {status}</div>
      <LikeBar />
      <button onClick={hideClickHandler} className="hide">
        Delete this status
      </button>
    </div>
  );
}

export default StatusBox;
