import Button from 'react-bootstrap/Button';
import currencypng from '../../components/Pics/currency.png';

const Currency = function () {
  return (
    <div className="portfolio-container">
      <h3>Exchange Rate Calculator</h3>
      <img
        className="portfolio-image"
        alt="interactive currency exchange app"
        src={currencypng}
        onClick={() =>
          window.open('https://jghportfolio.com/currency.html', '_blank')
        }
      />
      <div className="desc">
        <p>
          Calculate currency exchange rates. <br /> Swap between selected
          currencies.
          <br />
          Uses ExchangeRate-API.
        </p>
        <a href="https://jghportfolio.com/currency.html"> Live Preview </a>
        <br />
        <a href="https://github.com/jakeharris1078/currency">Github</a>
      </div>
    </div>
  );
};

export default Currency;
