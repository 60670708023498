import './ContactForm.css';
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Button from 'react-bootstrap/Button';

const ContactForm = function () {
  const [allValues, setAllValues] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = function (e) {
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
  };

  const form = useRef();
  const buttonRef = useRef(null);

  const sendEmail = (e) => {
    buttonRef.current.disabled = true;
    e.preventDefault();

    emailjs
      .sendForm(
        'service_p243r3b',
        'template_asg298s',
        form.current,
        '3nwEyL0A75RNOeFXo'
      )
      .then(
        () => {
          alert('Email sent!');
        },
        () => {
          alert(`Something went wrong!`);
        }
      );
    setAllValues({
      name: '',
      email: '',
      message: '',
    });
  };

  return (
    <div className="contact-container">
      <h2>Contact me</h2>
      <form id="contact-form" ref={form}>
        <input
          id="name-input"
          className="namefield"
          type="text"
          name="name"
          placeholder="Name"
          value={allValues.name}
          onChange={handleChange}
        ></input>
        <input
          className="emailfield"
          type="email"
          name="email"
          id="email-input"
          placeholder="Email address"
          value={allValues.email}
          onChange={handleChange}
        ></input>
        <input
          id="description-input"
          className="messagefield"
          name="message"
          placeholder="Message"
          value={allValues.message}
          onChange={handleChange}
        ></input>
        <br />
        <Button
          className="submit-btn"
          type="submit"
          onClick={sendEmail}
          ref={buttonRef}
        >
          Submit
        </Button>
      </form>
    </div>
  );
};

export default ContactForm;
