import './Summary.css';

import OtherSkills from './OtherSkills';
import ContactForm from './ContactForm';
import AboutSubgrid from './AboutSubgrid';
import PortfolioContainer from './PortfolioItems/PortfolioContainer';

const Summary = function () {
  return (
    <div className="flex-container">
      <AboutSubgrid />
      <PortfolioContainer />
      <hr />
      <OtherSkills />
      <hr />
      <ContactForm />
    </div>
  );
};

export default Summary;
