import statuspic from '../../components/Pics/status.png';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import Base from './ReactStatus/Base';

const ReactStatus = function () {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="portfolio-container">
      <h3>Frontend Status Mockup</h3>
      <img
        className="todo-maker-img"
        alt="interactive to-do list app"
        src={statuspic}
        onClick={handleShow}
      />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Frontend Status Maker Mockup</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Base />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="desc">
        <p>
          Add/remove status on dummy website. <br />
          Frontend only.
          <br /> Uses React.
        </p>
        <Button variant="link" onClick={handleShow}>
          <p className="a-style"> Live Preview</p>
        </Button>
      </div>
      <a
        className="hackfraud"
        href="https://github.com/jakeharris1078/first_react_project"
      >
        Github
      </a>
    </div>
  );
};

export default ReactStatus;
