import { useState } from 'react';
import './RS.css';
import StatusBox from './StatusBox';
import AddNew from './AddNew';

function Base() {
  const [statusList, setStatusList] = useState([
    <StatusBox key={'dummy'} statusText={'happy monday'} />,
  ]);

  return (
    <div className="base-app">
      <header className="RS-header">Status Place</header>
      <hr />
      <AddNew statusList={statusList} setStatusList={setStatusList} />
      {statusList}
    </div>
  );
}

export default Base;
