import pixelspng from '../../components/Pics/pixels.png';
import Button from 'react-bootstrap/Button';

const PixelArtMaker = function (props) {
  return (
    <div className="portfolio-container">
      <h3>Pixel Art Maker</h3>
      <img
        onClick={() =>
          window.open('https://jghportfolio.com/pixel.html', '_blank')
        }
        className="portfolio-image"
        alt="pixel art maker with adjustable grid height"
        src={pixelspng}
      />
      <div className="desc">
        <p>
          Adjustable grid size, pencil color, and grid color. <br />
          Eraser and clear.
        </p>
        <a href="https://jghportfolio.com/pixel.html"> Live Preview </a>
        <br />
        <a href="https://github.com/jakeharris1078/pixel-art-maker">Github</a>
      </div>
    </div>
  );
};

export default PixelArtMaker;
