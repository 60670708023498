import './PortfolioContainer.css';
import ToDoList from './ToDoList';
import PixelArtMaker from './PixelArtMaker';
import ReactStatus from './ReactStatus';
import Currency from './Currency';

const PortfolioContainer = function (props) {
  return (
    <div className="portfolio-container-wrapper">
      <h2>Projects</h2>
      <div className="items-container">
        <div className="currency-comp">
          <Currency />
        </div>
        <div className="todo-comp">
          <ToDoList />
        </div>
        <div className="pixels-comp">
          <PixelArtMaker />
        </div>
        <div className="status-comp">
          <ReactStatus />
        </div>
      </div>
    </div>
  );
};

export default PortfolioContainer;
