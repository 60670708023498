import todopng from '../../components/Pics/to-do-list.png';

const ToDoList = function () {
  return (
    <div className="portfolio-container">
      <h3>To Do List</h3>
      <img
        className="portfolio-image"
        alt="interactive to-do list app"
        src={todopng}
        onClick={() =>
          window.open('https://jghportfolio.com/todo.html', '_blank')
        }
      />
      <div className="desc">
        <p>
          Add and remove list items. <br /> Highlight random, shuffle.
          <br /> Save into .txt file. <br />
          Toggle dark/light mode.
        </p>
        <a href="https://jghportfolio.com/todo.html"> Live Preview </a>
        <br />
        <a href="https://github.com/jakeharris1078/interactive-to-do-list">
          Github
        </a>
      </div>
    </div>
  );
};

export default ToDoList;
