import './AboutSubgrid.css';
import headshot from './Pics/headshot.jpg';
import htmlcssIMG from './Pics/HTMLCSS.png';
import jsIMG from './Pics/JS.png';
import reactIMG from './Pics/react.png';

const AboutSubgrid = function () {
  return (
    <div>
      <h2 className="aboutme">About Me</h2>
      <div className="about-wrapper">
        <div className="pics-wrapper ">
          <img className="headshot" src={headshot} alt="headshot" />
          <div className="coding-pics">
            <img src={htmlcssIMG} alt="HTML and CSS icons" />
            <img className="js" src={jsIMG} alt="JS icon" />
            <img className="react" src={reactIMG} alt="React icon" />
          </div>
        </div>
        <div className="summary">
          <p>
            I am a self-taught programmer. I have intermediate skills in
            frontend JavaScript (and HTML + CSS). I have basic skills in React.
            I have worked with many in-house applications, databases, and both
            content and customer management systems for higher education and
            business clients.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutSubgrid;
