import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
import './OtherSkills.css';

const OtherSkills = function () {
  return (
    <div className="other-skills-container">
      <h2>Other Skills</h2>
      <ListGroup horizontal className="list-container group-1">
        <ListGroup.Item className="skill-item">
          <Card.Title className="card-title">Web skills</Card.Title>
          <Card.Text>Research, navigation, international</Card.Text>
        </ListGroup.Item>
        <ListGroup.Item className="skill-item">
          <Card.Title className="card-title">Marketing</Card.Title>
          <Card.Text>
            4 years
            <br /> Startup & established
          </Card.Text>
        </ListGroup.Item>
        <ListGroup.Item className="skill-item">
          <Card.Title className="card-title">Captioning</Card.Title>
          <Card.Text>
            4 years
            <br /> AI assisted
            <br /> 99.999% accuracy
          </Card.Text>
        </ListGroup.Item>
      </ListGroup>
      <ListGroup horizontal className="group-2">
        <ListGroup.Item className="skill-item">
          <Card.Title className="card-title">Writing</Card.Title>
          <Card.Text>
            BA in English (UIUC)
            <br /> 10 years freelance
          </Card.Text>
        </ListGroup.Item>
        <ListGroup.Item className="skill-item">
          <Card.Title className="card-title">Office Support</Card.Title>
          <Card.Text>
            10 years
            <br /> Startup & established
          </Card.Text>
        </ListGroup.Item>
        <ListGroup.Item className="skill-item">
          <Card.Title className="card-title">Backend </Card.Title>
          <Card.Text>Very basic skills and understanding</Card.Text>
        </ListGroup.Item>
      </ListGroup>
    </div>
  );
};

export default OtherSkills;
